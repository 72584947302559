require('@rails/ujs').start()
// require('turbolinks').start()
require('jquery')


import 'bootstrap'
import ScrollMagic from "scrollmagic";
import {TweenLite, TweenMax, Linear, TimelineMax} from 'gsap'


import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


$(function() {
  $('[data-play-hover]').each(function() {
    this.play();
    this.loop = false;
    this.onended = () => { this.currentTime = 0 };
    this.pause()
    // this.currentTime = 0
  })
})


$(document).on('mouseover', '[data-play-hover]', function() {
  this.play();
  this.loop = true;
})


$(document).on('mouseleave', '[data-play-hover]', function() {
  this.loop = false;
  this.onended = () => { this.currentTime = 0 };
})


$(document).on('click', '.menu-links-toggle', () => {
  $('.hamburger-icon').toggleClass('open');
});


$(document).on('do-collapse', '[data-menu-links]', (e) => {
  $(e.target).collapse('hide');
  $('.hamburger-icon').removeClass('open');
});


$(document).on('scroll', () => {
  $('[data-menu-links]').trigger('do-collapse');
});


$(document).on('click', '[data-scroll-link]', (e) => {
  const offset = $(e.target.hash).offset();

  if (e.target.href.indexOf(window.location.href.split("#")[0]) > -1) {
    e.preventDefault();
  }

  $('[data-menu-links]').trigger('do-collapse');

  $([document.documentElement, document.body]).animate({
      scrollTop: ((offset || {}).top || 0) - 58
  }, 700);
});


$(document).on('slid.bs.carousel', '#projects-carousel', ({to}) => {
  $('[data-carousel-indicator]').text(to + 1)
  // do something…
})


$(() => {
  let prevScrollpos = window.pageYOffset;

  window.onscroll = function() {
    let currentScrollPos = window.pageYOffset;

    if (prevScrollpos > currentScrollPos) {
      $("#navbar").removeClass('hidden')
    } else {
      $("#navbar").addClass('hidden')
    }

    prevScrollpos = currentScrollPos;
  }
})


jQuery.extend( jQuery.easing,
{
    def: 'easeInOutSine',
    swing: function (x, t, b, c, d) {
        //alert(jQuery.easing.default);
        return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    easeInQuad: function (x, t, b, c, d) {
        return c*(t/=d)*t + b;
    },
    easeOutQuad: function (x, t, b, c, d) {
        return -c *(t/=d)*(t-2) + b;
    },
    easeInOutQuad: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t + b;
        return -c/2 * ((--t)*(t-2) - 1) + b;
    },
    easeInCubic: function (x, t, b, c, d) {
        return c*(t/=d)*t*t + b;
    },
    easeOutCubic: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t + 1) + b;
    },
    easeInOutCubic: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t + b;
        return c/2*((t-=2)*t*t + 2) + b;
    },
    easeInQuart: function (x, t, b, c, d) {
        return c*(t/=d)*t*t*t + b;
    },
    easeOutQuart: function (x, t, b, c, d) {
        return -c * ((t=t/d-1)*t*t*t - 1) + b;
    },
    easeInOutQuart: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
        return -c/2 * ((t-=2)*t*t*t - 2) + b;
    },
    easeInQuint: function (x, t, b, c, d) {
        return c*(t/=d)*t*t*t*t + b;
    },
    easeOutQuint: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t*t*t + 1) + b;
    },
    easeInOutQuint: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
        return c/2*((t-=2)*t*t*t*t + 2) + b;
    },
    easeInSine: function (x, t, b, c, d) {
        return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
    },
    easeOutSine: function (x, t, b, c, d) {
        return c * Math.sin(t/d * (Math.PI/2)) + b;
    },
    easeInOutSine: function (x, t, b, c, d) {
        return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
    },
    easeInExpo: function (x, t, b, c, d) {
        return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
    },
    easeOutExpo: function (x, t, b, c, d) {
        return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t==0) return b;
        if (t==d) return b+c;
        if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
        return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    },
    easeInCirc: function (x, t, b, c, d) {
        return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
    },
    easeOutCirc: function (x, t, b, c, d) {
        return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
    },
    easeInOutCirc: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
        return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
    },
    easeInElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
    },
    easeOutElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
    },
    easeInOutElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
        return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
    },
    easeInBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c*(t/=d)*t*((s+1)*t - s) + b;
    },
    easeOutBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
    },
    easeInOutBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158; 
        if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
        return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
    },
    easeInBounce: function (x, t, b, c, d) {
        return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
    },
    easeOutBounce: function (x, t, b, c, d) {
        if ((t/=d) < (1/2.75)) {
            return c*(7.5625*t*t) + b;
        } else if (t < (2/2.75)) {
            return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
        } else if (t < (2.5/2.75)) {
            return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
        } else {
            return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
        }
    },
    easeInOutBounce: function (x, t, b, c, d) {
        if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
        return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
    }
});


// Production
$(document).on('mouseenter mouseleave', 'a[data-show-project-preview]', function (e) {

    const className = "project-thumbnail-visible"
    , id = this.dataset.showProjectPreview;

    $('[data-project-preview]').removeClass(className);

    if ( e.type === 'mouseenter') {
      $("[data-project-preview=" + id + "]").addClass(className);
    }
  })




$(function() {
  if (!document.querySelector('#opacity-trigger-1')) { return }

  var controller = new ScrollMagic.Controller();

  // SECTION COUNTER
  //section1
  new ScrollMagic.Scene({
    triggerElement: '#opacity-trigger-1',
    duration: "30%",
    offset: "0%",
  })
  .setTween(
      TweenMax.fromTo(
          ".sec-1",
          0.1,
          { opacity:0 },
          { opacity:1 }
      )
  )
  .addTo(controller);


  //section2
  new ScrollMagic.Scene({
    triggerElement: '#opacity-trigger-2',
    duration: "30%",
    offset: "0%",
  })
  .setTween(
      TweenMax.fromTo(
          ".sec-2",
          0.1,
          { opacity: 0 },
          { opacity: 1 }
      )
  )
  .addTo(controller);


  //section3
  new ScrollMagic.Scene({
      triggerElement: '#trigger-3',
      duration: "30%",
      offset: "0%",
  })
  .setTween(
      TweenMax.fromTo(
          ".sec-3",
          0.1,
          { opacity: 0 },
          { opacity: 1 }
      )
  )
  .addTo(controller);

  //section4
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-4',
      duration: "30%",
      offset: "0%",
  })
  .setTween(
      TweenMax.fromTo(
          ".sec-4",
          0.1,
          {opacity:0},
          {opacity:1}
      )
  )
  .addTo(controller);



  // First black circle
  const ovalSelector = ".oval-inner-big-1"

  new ScrollMagic.Scene({
      triggerElement: '#trigger-1',
      duration: "150%",
  })
  .setTween(
      new TimelineMax()
          .set(ovalSelector, { display: "block", transform: "scale(2)"})
          .to(ovalSelector, 15,
              { transform: "scale(0)",
                display: "none"
              })
          .to(ovalSelector, 1, { display: "none" })
  )
  .addTo(controller);


  // Scroll Text opacity
  new ScrollMagic.Scene({
      triggerElement: '#trigger-1',
      duration: "50%",
      offset:"50%"
  })
  .setTween(
      TweenMax.fromTo(
          ".scroll-text",
          0.1,
          {opacity: 1},
          {opacity: 0}
      )
  )
  .addTo(controller);


  // first-paragraph opacity
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-1',
      duration: "50%",
      offset:"20%"
  })
  .setTween(
      TweenMax.fromTo(
          ".first-paragraph",
          0.1,
          {opacity: 1},
          {opacity: 0}
      )
  )
  .addTo(controller);



  // second-paragraph opacity timeline
  var secondPara = new TimelineMax();
  secondPara.set(".second-paragraph", {opacity: 0})
      .to(".second-paragraph", 0.1, {opacity: 1})
      .to(".second-paragraph", 0.3, {opacity: 0})

  // second_paragraph scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-1',
      duration: "60%",
      offset:"120%"
  })
  .setTween(secondPara)
  .addTo(controller);



  //question-2 opacity timeline
  var questionTwo = new TimelineMax();

  questionTwo.set("#question-2", {opacity: 0})
      .to("#question-2", 0.3, {opacity: 1})
      .to("#question-2", 0.1, {opacity: 0})


  //question-2 scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"-380%"
  })
  .setTween(questionTwo)
  .addTo(controller);


  //answer-2A opacity timeline
  var answerTwoA = new TimelineMax();

  answerTwoA.set("#answer-2A", {opacity: 0})
      .to("#answer-2A", 0.3, {opacity: 1})
      .to("#answer-2A", 0.1, {opacity: 0})


  //answer-2A scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"-120%"
  })
  .setTween(answerTwoA)
  .addTo(controller);


  //Oval1 opacity timeline
  var OvalOne = new TimelineMax();
  OvalOne.set("#oval-inner-1", {opacity: 0})
      .to("#oval-inner-1", 0.3, {opacity: 1})
      .to("#oval-inner-1", 0.1, {opacity: 0})


  //Oval1 scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"-120%"
  })
  .setTween(OvalOne)
  .addTo(controller);


  const animateBlueRing = ({selector, trigger, offset}) => {
    new ScrollMagic.Scene({
        triggerElement: trigger,
        duration: "100%",
        offset: offset,
    })
    .setTween(
        TweenMax.fromTo(
            selector,
            0.1,
            { transform: "scale(0)",
              display: "block",
              opacity:0.3 },
            { transform: "scale(1)",
              display: "none",
              opacity:0 }
        )
    )
    .addTo(controller);
  }


  // Animate first translucent blue ring
  animateBlueRing({
    selector: ".ring-inner-1",
    trigger: '#trigger-2',
    offset: '-5%'
  })

  // Animate second translucent blue ring
  animateBlueRing({
    selector: ".ring-inner-2",
    trigger: '#trigger-2',
    offset: "225%",
  })

  // Animate third translucent blue ring
  animateBlueRing({
    selector: ".ring-inner-3",
    trigger: '#trigger-2',
    offset: "440%",
  })


  // Animate big translucent blue ring
  new ScrollMagic.Scene({
      triggerElement: "#trigger-3",
      duration: "100%",
      offset: "180%",

  })
  .setTween(
      new TimelineMax()
          .set(".ring-inner-4", { transform: "scale3d(0, 0, 0)",
            display: "block"})
          .to(".ring-inner-4", 0.3, { transform: "scale3d(1.5, 1.5, 1.5)",
            display: "block", })
          .to(".ring-inner-4", 0.1, { opacity: 0,
            display: "none", })
  )
  .addTo(controller);



  //answer-2B opacity timeline
  var answerTwoB = new TimelineMax();

  answerTwoB.set("#answer-2B", {opacity: 0})
      .to("#answer-2B", 0.3, {opacity: 1})
      .to("#answer-2B", 0.1, {opacity: 0})


  //answer-2B scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"140%"
  })
  .setTween(answerTwoB)
  .addTo(controller);


  //Oval2 opacity timeline
  var OvalTwo = new TimelineMax();

  OvalTwo.set("#oval-inner-2", {opacity: 0})
      .to("#oval-inner-2", 0.3, {opacity: 1})
      .to("#oval-inner-2", 0.1, {opacity: 0})


  //Oval2 scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"140%"
  })
  .setTween(OvalTwo)
  .addTo(controller);



  //answer-2C opacity timeline
  var answerTwoB = new TimelineMax();

  answerTwoB.set("#answer-2C", {opacity: 0})
      .to("#answer-2C", 0.3, {opacity: 1})
      .to("#answer-2C", 0.1, {opacity: 0})


  //answer-2C scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-2',
      duration: "80%",
      offset:"400%"
  })
  .setTween(answerTwoB)
  .addTo(controller);



  // Oval3
  new ScrollMagic.Scene({
      triggerElement: '#trigger-2',
      duration: "20%",
      offset: "225%",
  })
  .setTween(
      TweenMax.fromTo(
          "#oval-inner-3",
          0.1,
          {opacity:0},
          {opacity:1}
      )
  )
  .addTo(controller);






  // bigOval2 timeline
  var bigOval2 = new TimelineMax();

  bigOval2.set(".oval-inner-big-2", {transform:"scale(0)"})
      .to(".oval-inner-big-2", 0.1, {transform:"scale(1.8)"})
      .to(".oval-inner-big-2", 0.3, {opacity: 0, display:"none"})

  // bigOval2 scene
  var bigOval2Offset;

  if ($(window).width() > 600) {
      bigOval2Offset = "600%"
  } else if ($(window).width() > 2500) {
      bigOval2Offset = "800%"
  } else {
      bigOval2Offset = "400%"
  }


  new ScrollMagic.Scene({
      triggerElement: '#trigger-2',
      duration: "140%",
      offset: bigOval2Offset,
  })
  .setTween(bigOval2)
  .addTo(controller);


  //Question 3 opacity timeline
  var questionThree = new TimelineMax();

  questionThree.set("#question-3", {opacity: 1})
      .to("#question-3", 0.3, {opacity: 1})
      .to("#question-3", 0.1, {opacity: 0})


  //Question 3 scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-3',
      duration: "60%",
      offset:"-120%"
  })
  .setTween(questionThree)
  .addTo(controller);



  //contourCircles
  new ScrollMagic.Scene({
      triggerElement: "#trigger-3",
      duration: "100%",
      offset: "-210%",

  })
  .setTween(
      TweenMax.fromTo(
          ".bg-contour-1",
          0.1,
          {transform:"scale(0)", display: "block"},
          {transform:"scale(2)", display: "none"}
      )
  )
  .addTo(controller);


  new ScrollMagic.Scene({
      triggerElement: "#trigger-3",
      duration: "100%",
      offset: "-60%",

  })
  .setTween(
      TweenMax.fromTo(
          ".bg-contour-2",
          0.1,
          {transform:"scale(0)", display: "block"},
          {transform:"scale(2)", display: "none"}
      )
  )
  .addTo(controller);


  //Statement opacity timeline
  var statement = new TimelineMax();

  statement.set("#sentence", {opacity: 1})
      .to("#sentence", 0.3, {opacity: 1})
      .to("#sentence", 0.1, {opacity: 0})


  //Statement scene
  new ScrollMagic.Scene({
      triggerElement: '#opacity-trigger-4',
      duration: "80%",
      offset:"-220%"
  })
  .setTween(statement)
  .addTo(controller);


  //graph desktop
  new ScrollMagic.Scene({
      triggerElement: '#trigger-3',
      duration: "100%",
      offset: "440%",

  })
  .setTween(
      TweenMax.fromTo(
          ".graph img",
          0.1,
          {transform:"scale(0.30)", opacity:0},
          {transform:"scale(0.7)", opacity:1}
      )
  )
  .addTo(controller);


//graph mobile
new ScrollMagic.Scene({
    triggerElement: '#trigger-3',
    duration: "80%",
    offset: "440%",

})
.setTween(
    TweenMax.fromTo(
        ".graph-mobile img",
        0.1,
        {transform:"scale(0.30)", opacity:0},
        {transform:"scale(0.9)", opacity:1}
    )
)
.addTo(controller);

});


$(function() {
  var controller = new ScrollMagic.Controller()

  $('.portfolio-image').each(function(idx) {
    var imageNum = idx + 1
      , selector = '.portfolio-image:nth-child(' + imageNum + ')'
      , timeline = new TimelineMax();

    $('.image-counter').hide()

    timeline.add(
      TweenMax.fromTo(
        "span.current:nth-child(" + imageNum + ")",
        0.01,
        { display: "none" },
        { display: "inline" }
      )
    )

    new ScrollMagic.Scene({
      triggerElement: selector,
      offset: "-30%",
    })
    .setTween(timeline)
    .addTo(controller);

    $('.image-counter').fadeIn()
  })
})


//Make scroll-to-top smooth again
$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
      && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 900);
        return false;
      }
    }
  });
});
